@use "../../styles/abstracts" as *;

$gdpr-content-wrapper-max-width: 1000px;

.gdpr-content-wrapper {
  padding: $spacing-m;
  margin: $spacing-m auto;
  max-width: $gdpr-content-wrapper-max-width;

  h1 {
    margin: $spacing-xl auto;
    // font-size: $title-font-size;
    text-align: center;
  }

  font-size: $text-font-size;
}
