@use "../../styles/abstracts" as *;

$button-inner-padding: 15px;
$button-border-radius: 25px;
$button-text-font-size: 15px; 
.button {
  border-radius: $button-border-radius;
  border-style: solid;
  border-width: 1px;
  color: white;
  text-decoration: none;
  padding: $button-inner-padding;
  font-size: $button-text-font-size;
  line-height: $button-text-font-size;
  font-weight: $font-weight-bold;
  transition: 0.15s;
  cursor:pointer;
  display: inline-block;
}
.button--disabled{
  cursor: not-allowed;
  opacity:0.7;
}

@mixin button-variant($variant-color) {
  background-color: $variant-color;
  border-color:  darken($variant-color, 10);
  &:hover:not(.button--disabled) {
    background-color: darken($variant-color, 10);
  }
}

.button--metalwork {
  @include button-variant($metalwork-color);
}
.button--trailers {
  @include button-variant($trailers-color);
}
.button--fences {
  @include button-variant($fences-color);
}
.button--default {
  @include button-variant($default-color);
}
