@use "../../styles/abstracts" as *;
$metalwrok-demand-form-padding: 60px 0;

.metalwork-demand-form {
  padding: $metalwrok-demand-form-padding;
}
.demand-form-scroll-button {
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-weight: $font-weight-bold;
  cursor: pointer;
  svg {
    font-size: 1.5*$text-font-size-small;
    vertical-align: middle;
  }
}



.demand-form-scroll-button--hidden{
  display:none;
}

.demand-form--separated{
  border-top: 5px solid $metalwork-color
}
