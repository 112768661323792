@use "./styles/abstracts" as *;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: $title-font-size;

  @media only screen and (max-width: $screen-lg) {
    font-size: $title-font-size-screen-md
  }
}

h2 {
  font-size: $sub-title-font-size;

  @media only screen and (max-width: $screen-lg) {
    font-size: $sub-title-font-size-screen-md
  }
}


h3{
  font-size: $sub-sub-title-font-size
}


p {
  font-size: $text-font-size;

  @media only screen and (max-width: $screen-lg) {
    font-size: $text-font-size-screen-md
  }

}