@use "../../styles/abstracts" as *;


$hero-text-color: white;
$hero-image-not-loaded-bg-color: #cccccc;

//hero
$hero-content-padding: 70px 20px;
$hero-minimal-height: 80vh;

//hero text
$hero-text-container-max-width: 1000px;
$hero-icon-padding-top: 50px;


.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $hero-image-not-loaded-bg-color;
  padding: $hero-content-padding;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: $hero-minimal-height;
}

.hero__text-container {
  color: $hero-text-color;
  max-width: $hero-text-container-max-width;

  svg {
    padding-top: $hero-icon-padding-top;
    cursor: pointer;
  }
}
