@use "../../styles/abstracts" as *;
//content module
$content-box-minimal-height: 500px;

//Title and par styles
$title-underline-padding: 2px;
$texts-and-button-gap: 80px;
$content-wrapper-padding: 20px 40px;
$description-paragraph-max-width: 700px;
$description-paragraph-padding: 30px 0;

//button
$button-padding: 20px;
$button-inner-padding: 15px;
$button-border-radius: 30px;
$button-font-size: 20px;

//screen <xl changes
$content-box-minimal-height-screen-lg: 350px;
$texts-and-button-gap-screen-lg: 30px;
$description-paragraph-padding-screen-lg: 20px 0 0 0;

//screen <lg
$content-box-minimal-height-screen-md: 300px;
$texts-and-button-gap-screen-md: 0;
$button-padding-screen-md: 20px 0;
$description-paragraph-padding-screen-md: 15px 0 0 0;

//screen<md
$content-padding-screen-sm: 10px 0;
$content-container-padding-screen-sm: 20px 40px 0 40px;
$button-padding-screen-sm: 20px 0;

.content-box {
  min-height: $content-box-minimal-height;
  display: flex;

  @media only screen and (max-width: $screen-xl) {
    min-height: $content-box-minimal-height-screen-lg;
  }

  @media only screen and (max-width: $screen-lg) {
    min-height: $content-box-minimal-height-screen-md;
  }
}

.content-box--separated {
  border-top-style: solid;
  box-sizing: border-box;
  border-width: 5px;


  &.variant-metalwork{
    border-top-color:  $metalwork-color;
  }
  &.variant-trailers{
    border-top-color:  $trailers-color;
  }
  &.variant-fences  {
    border-top-color:  $fences-color;
  }
}

.content-box--default {
  flex-direction: row;
}
.content-box--reverse {
  flex-direction: row-reverse;
}
.content-box__image {
  flex: 1;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: $screen-sm) {
    display: none;
  }
}

.content-box__content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: $texts-and-button-gap;

  align-content: center;

  //small screen background
  background-size: cover;
  background-position: center;

  //min instead of max-width used - need to add +1px to condition
  @media only screen and (min-width: calc(#{$screen-sm} + 1px)) {
    background-image: none !important; //rewrite inline styles
    justify-content: center !important;
  }

  @media only screen and (max-width: $screen-xl) {
    column-gap: $texts-and-button-gap-screen-lg;
  }

  @media only screen and (max-width: $screen-lg) {
    column-gap: $texts-and-button-gap-screen-md;
  }

  @media only screen and (max-width: $screen-sm) {
    padding: $content-container-padding-screen-sm;
  }
}
.content-box__content--left {
  justify-content: flex-start;
}
.content-box__content--right {
  justify-content: flex-end;
}

.content-box__content-wrapper {
  display: inline-block;
  align-self: center;
  padding: $content-wrapper-padding;

  @media only screen and (max-width: $screen-sm) {
    color: white;
    padding: 0;
  }
}

.content-box__button-container {
  padding: $button-padding;
  align-self: center;

  @media only screen and (max-width: $screen-lg) {
    padding: $button-padding-screen-md;
  }
  @media only screen and (max-width: $screen-md) {
    padding: $button-padding-screen-sm;
  }
}

.content-box__title {
  padding-bottom: $title-underline-padding;
  display: inline-block;
  margin: 0;
  font-weight: $font-weight-bold;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}
@mixin content-box__title-variant($variant-color) {
  border-bottom-color: $variant-color;
}

.content-box__title--metalwork {
  @include content-box__title-variant($metalwork-color);
}

.content-box__title--trailers {
  @include content-box__title-variant($trailers-color);
}

.content-box__title--fences {
  @include content-box__title-variant($fences-color);
}
.content-box__title--default {
  @include content-box__title-variant($default-color);
}
.content-box__description {
  margin: 0;
  max-width: $description-paragraph-max-width;
  padding: $description-paragraph-padding;
  text-align: justify;
  @media only screen and (max-width: $screen-lg) {
    padding: $description-paragraph-padding-screen-lg;
  }

  @media only screen and (max-width: $screen-md) {
    padding: $description-paragraph-padding-screen-md;
  }
}
