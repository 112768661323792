
$links-font-size: 24px;
$links-font-size-small: 20px;
$text-font-size: 24px;
$text-font-size-small:17px;
$text-font-size-screen-md: 17px;

$title-font-size: 50px;
$title-font-size-screen-md: 35px;
$sub-title-font-size: 25px;
$sub-sub-title-font-size: 22px;
$sub-title-font-size-screen-md: 20px;

$font-weight-regular: 400;
$font-weight-bold: 700;
