@use "../../styles/abstracts" as *;

$kontakt-content-wrapper-max-width: 1700px;

.kontakt-page {
  padding: $spacing-m;
  margin: $spacing-m auto;
  max-width: $kontakt-content-wrapper-max-width;

  & p {
    margin: $spacing-s;
  }

  & h3 {
    display: inline-block;
    font-size: $text-font-size;
    border-bottom: solid 3px $default-color;
  }

  & a {
    color: $default-color;

    &:hover {
      color: black;
    }
  }

  font-size: $text-font-size;
}

.kontakt-page__title-wrapper {
  text-align: center;
  & h2 {
    font-size: $title-font-size;
    border-bottom: solid 3px $default-color;
    display: inline-block;
  }
}

.kontakt-page__content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.kontakt-page__content-item {
  width: 440px;
  padding: 30px;
}
