.page-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

html,
body,
.page-container {
  height: 100%;
}
