@use "../../styles/abstracts" as *;

.career__content {
  padding: $spacing-m;
  margin: $spacing-m auto;
  max-width: 80%;
}
.career__motivation p {
  font-size: $text-font-size-small;
}

.career__more-info {
  a {
    text-decoration: none;
    color: black;
    font-weight: $font-weight-bold;
  }
}
.career__more-info {
  font-size: $text-font-size-small;
}

.job {
  margin: $spacing-m 0;
}

.career__jobs-wrapper {
  display: flex;
  column-gap: $spacing-m;
  flex-wrap: wrap;
  justify-content: center;
}
