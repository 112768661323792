@use "../../styles/abstracts" as *;
$job-box-width: 450px;
.job {
  border: 1px solid rgba(149, 157, 165, 0.35);
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  border-radius: 5px;
  padding: $spacing $spacing-m;
  width:$job-box-width
}
.job__description {
  font-size: $text-font-size-small;
  margin: $spacing-m 0 0 0;
}
.job__name {
  display: inline-block;
  border-bottom: 3px solid $default-color;
  margin: $spacing 0 0 0;
}
.job__offers-requirements {
  padding: $spacing 0;
}
.job__requirements-list,
.job__offers-list {
  & ul {
    margin: 0;
  }
  padding: $spacing 0;
}

.job__requirements-list__title,
.job__offers-list__title {
  font-size: $text-font-size-small;
  font-weight: $font-weight-bold;
  padding: $spacing 0;
}
