@use "../../styles/abstracts" as *;

//footer
$footer-height-large-screen: 400px;
$footer-background: rgb(43, 43, 43);
$footer-text-color: white;
//footer main sections
$footer-sections-padding: 30px 100px;
$sections-gap: 40px;

//contacts section
$social-link-padding: 10px 0;
$social-text-icon-gap: 15px;
$social-link-icon-size: $text-font-size*2;

//map section
$map-height: 300px;

//bottom text section
$bottom-text-section-padding: 15px 0;
$footer-bottom-text-section-background: darken($footer-background, 5%);

//screen <xl
$footer-sections-padding-screen-lg: 30px 70px;
$social-link-icon-size-screen-lg: $text-font-size*1.5;

//small screen
$footer-sections-padding-small-screen: 10px 20px;

@mixin footer-variant($variant-color) {
  h2 {
    border-bottom-color: $variant-color;
  }
}

.footer--metalwork {
  @include footer-variant($metalwork-color);
}

.footer--trailers {
  @include footer-variant($trailers-color);
}

.footer--fences {
  @include footer-variant($fences-color);
}

.footer--default {
  @include footer-variant($default-color);
}

.footer {
  background-color: $footer-background;
  color: $footer-text-color;

  h2 {
    padding-bottom: 2px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    width: max-content;
  }
}

.footer__sections {
  padding: $footer-sections-padding;
  display: flex;
  flex-flow: row wrap;
  column-gap: $sections-gap;
  max-width: 70%;
  margin: 0 auto;

  @media only screen and (max-width: $screen-xxl) {
    max-width: 100%;
  }

  @media only screen and (max-width: $screen-xl) {
    padding: $footer-sections-padding-screen-lg;
  }

  @media only screen and (max-width: $screen-xl) {
    padding: $footer-sections-padding-screen-lg;
  }

  @media only screen and (max-width: $screen-sm) {
    padding: $footer-sections-padding-small-screen;
  }
}

.footer__map-section {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.footer__map-frame-container {
  flex: auto;
  width: 100%;
  height: $map-height;

  .footer__map-frame {
    width: 100%;
    border: 0;
    height: 100%;
  }
}

@media only screen and (max-width: $screen-lg) {

  .footer__map-section,
  .footer__contacts-section {
    flex: auto;
  }
}

.footer__contacts-section {
  flex: 1;

  @media only screen and (max-width: $screen-xl) {
    flex: 2;
  }
}

.footer__socials {
  a {
    display: block;
    text-decoration: none;
    color: $footer-text-color;
    font-size: $links-font-size;

    @media only screen and (max-width: $screen-lg) {
      font-size: $links-font-size-small;
    }


    .footer__social-container {
      column-gap: $social-text-icon-gap;
      padding: $social-link-padding;
      display: flex;
      flex-flow: row nowrap;
      line-height: $social-link-icon-size;
      @media only screen and (max-width: $screen-lg) {
        line-height: $social-link-icon-size-screen-lg;

      }
      .footer__social-icon {
        width: $social-link-icon-size;
        height: $social-link-icon-size;
        border-radius: $social-link-icon-size;
        text-align: center;
        position: relative;

        @media only screen and (max-width: $screen-lg) {
          width: $social-link-icon-size-screen-lg;
          height: $social-link-icon-size-screen-lg;
          border-radius: $social-link-icon-size-screen-lg;
        }

        svg {
          //centering icon in circle
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          @media only screen and (max-width: $screen-lg) {
            font-size: $text-font-size-screen-md
          }
        }
      }
    }
  }
}

.footer__images-section {
  flex: 1;
}

.footer__images-container {
  img {
    max-height: 100px;
    padding: 5px;
    display: block;
  }

  @media only screen and (max-width: $screen-sm) {
    img {
      max-height: fit-content;
      max-width: 100%;
    }
  }
}

.footer__authors {
  padding: $bottom-text-section-padding;
  background-color: $footer-bottom-text-section-background;
  text-align: center;
}