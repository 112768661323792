@use "../../styles/abstracts" as *;

//form container
$form-container-width: 60%;



//form inputs
$input-container-padding: 5px 0 2px 0;
$label-font-size: $text-font-size-small;
$label-padding: 10px 0 7px 0;
$input-inner-padding: 12px;
$input-border-width: 1px;
$input-border-color: gray;
$input-border-radius: 10px;
$textarea-min-height: 100px;
$input-font-size: $text-font-size-small;
//buttons
$buttons-horizontal-gap: 20px;
//gdpr agreement paragraph
$gdpr-agreement-padding: 15px 0;

//screen md
$form-container-width-screen-md: 75%;

//color variants
@mixin form-variant($variant-color) {
  h2 {
    border-color: $variant-color;
  }
  input:focus-visible,
  textarea:focus-visible {
    outline: $variant-color auto 1px;
  }
}

.variant-metalwork {
  @include form-variant($metalwork-color);
}
.variant-trailers {
  @include form-variant($trailers-color);
}
.variant-fences {
  @include form-variant($fences-color);
}

.demand-form-container {
  margin: 0 auto;
  width: $form-container-width;

  @media only screen and (max-width: $screen-lg) {
    width: $form-container-width-screen-md;
  }

  h2 {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    width: max-content;
    padding-bottom: 2px;
  }
}

.demand-form__input-container {
  padding: $input-container-padding;
  label {
    display: block;
    font-size: $label-font-size;
    padding: $label-padding;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: $input-inner-padding;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    box-sizing: border-box;
    font-size: $input-font-size;
  }
  textarea {
    min-height: $textarea-min-height;
    max-width: 100%;
    min-width: 100%;
    font-family: inherit;
  }
}

.demand-form__inputs-flex-2 {
  .demand-form__input-container {
    display: inline-block;
    width: 49%;
    &:nth-child(2) {
      margin-left: 2%;
    }
  }
  @media only screen and (max-width: $screen-sm) {
    .demand-form__input-container {
      display: block;
      width: 100%;
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
}

.demand-form__attachment-upload-btn {
  pointer-events: none;
    svg {
      font-size: 1.5*$text-font-size-small;
      vertical-align: middle;
    }
}

.demand-form__buttons {
  display: flex;
  justify-content: center;
  column-gap: $buttons-horizontal-gap;
  label {
    cursor: pointer;
  }
}

.demand-form__gdpr-agreement {
  text-align: center;
  p {
    font-size: $text-font-size-small;
    padding: $gdpr-agreement-padding;
    a {
      color: inherit;
    }
  }
}

.demand-form__submit-status {
  text-align: center;
  padding-top: 10px;
  font-weight: $font-weight-bold;
}
