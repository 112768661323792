@use "../../styles/abstracts" as *;

//navbar
$navbar-content-padding-vertical: 5px 50px;
$navbar-min-height: 60px;
$navbar-padding-small-screen: 0px;
$nav-background: rgb(246, 246, 246);
$nav-item: black;
$nav-item--hover: $default-color;
//navbar header
$header-padding-small-screen: 10px 20px;
$header-image-max-width: 200px;

//hamburger menu button
$hamburger-button-line-width: 32px;
$hamburger-button-line-height: 4px;
$hamburger-button-line-margin: 4px;
$hamburger-button-animation-duration: 150ms;

//navbar links
$navbar-links-padding-screen-small:10px 20px;
$navbar-link-padding: 0 15px;
$navbar-link-padding-small-screen: 10px;
$navbar-link-hover-animation-duration: 150ms;
$navbar-link-underline-height: 3px;
$navbar-underline-margin-top: 1px;

//navbar dropdown menu
$navbar-dropdown-animation-duration: 150ms;

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $navbar-content-padding-vertical;
  background-color: $nav-background;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  min-height: $navbar-min-height;

  @media only screen and (max-width: $screen-lg) {
    display: block;
    padding: $navbar-padding-small-screen;
    background-color: transparent;
    box-shadow: none;
  }
}

.navbar__header {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $screen-lg) {
    padding: $header-padding-small-screen;
    background-color: $nav-background;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  }
}

.navbar__image {
  max-width: $header-image-max-width;
}

.navbar__toggle-button--open {
  i {
    &:nth-child(1) {
      transform: translateY(12px) rotate(45deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translateY(-12px) rotate(-45deg);
    }
  }
}

.navbar__toggle-button {
  flex-direction: column;
  cursor: pointer;
  display: none;
  i {
    background-color: black;
    width: $hamburger-button-line-width;
    height: $hamburger-button-line-height;
    margin: $hamburger-button-line-height;
    border-radius: $hamburger-button-line-height; //radius same as height = round edge
    transition: $hamburger-button-animation-duration ease-in-out;
  }
  @media only screen and (max-width: $screen-lg) {
    display: flex;
  }
}

.navbar__toggle-button--close {
  i {
    &:nth-child(2) {
      opacity: 1;
    }
  }
}

.navbar__links-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: $screen-lg) {
    position: absolute;
    width: 100%;
    background-color: $nav-background;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    padding: $navbar-links-padding-screen-small;
  }

  li {
    display: inline-block;
    padding: $navbar-link-padding;

    @media only screen and (max-width: $screen-lg) {
      display: block;
      padding: $navbar-link-padding-small-screen;
    }

    a {
      color: $nav-item;
      text-decoration: none;
      font-size: $links-font-size;
      display: block;
      font-weight: $font-weight-bold;

      &:hover {
        color: $nav-item--hover;
        transition: $navbar-link-hover-animation-duration ease-in-out;
      }

      //underline animation on big screens, +1px because min width is used instead of max width (>screenlg)
      @media only screen and (min-width: calc(#{$screen-lg} + 1px)) {
        &:hover:after {
          width: 100%;
          background: $nav-item--hover;
        }

        &:after {
          content: "";
          display: block;
          height: $navbar-link-underline-height;
          width: 0;
          margin: $navbar-underline-margin-top auto 0 auto;
          background: transparent;
          transition: width $navbar-link-hover-animation-duration ease,
            background-color $navbar-link-hover-animation-duration ease;
        }
      }
    @media only screen and (max-width: $screen-xl) {
      font-size: $links-font-size-small
    }
    }
  }
}

.navbar__links-menu--open {
  transform-origin: top;
  transform: scaleY(1);
  transition: all 0s;
  @media only screen and (max-width: $screen-lg) {
    transition: all $navbar-dropdown-animation-duration ease-in-out;
  }
}

.navbar__links-menu--closed {
  transform-origin: top;
  transform: scaleY(1);
  transition: all 0s;
  @media only screen and (max-width: $screen-lg) {
    transform: scaleY(0);
    transition: all $navbar-dropdown-animation-duration ease-in-out;
  }
}
